import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import GlobalLayout from 'components/GlobalLayout'
import OmniSearchHome from 'components/OmniSearchHome'
import { stampTime, eventDispatch } from 'utils/tracker'
import { css } from '@emotion/core'
import { COLORS, SPACERS, MQ } from 'utils/styleHelpers'
function SearchPage({ location }) {
	const [defaultSearchTerm, setDefaultSearchTerm] = useState('')

	const [defaultFilter, setDefaultFilter] = useState(null)
	useEffect(() => {
		if (location.state && location.state.searchTerm) {
			setDefaultSearchTerm(location.state.searchTerm)
		}
		if (location.state && location.state.selectedFilter) {
			setDefaultFilter(location.state.selectedFilter)
		}
	}, [location])

	return (
		<GlobalLayout isDisplayingSearch={false} location={location}>
			<div
				css={css`
						${MQ.L} {
							background: ${COLORS.MEDIUM_GRAY};
							padding-top: ${SPACERS.M};
							padding-bottom: ${SPACERS.M};
						}
					`}
			>
				<OmniSearchHome
					defaultFilter={defaultFilter}
					defaultSearchTerm={defaultSearchTerm}
				/>
			</div>
		</GlobalLayout>
	)
}

SearchPage.propTypes = {
	location: PropTypes.object,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(SearchPage)
