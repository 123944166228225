// externals
import React from 'react'
import PropTypes from 'prop-types'
import { InstantSearch } from 'react-instantsearch/dom'
import algoliasearch from 'algoliasearch/lite'
import styled from '@emotion/styled'
// components
import Search from 'components/Search'
// constants
import { credentials } from 'secrets/algolia-key'
import { MQ } from 'utils/styleHelpers'
// imgs

const OmniSearchWrapper = styled.div`
	max-width: 560px;
	margin: 0 auto;

	${MQ.L} {
		background-color: transparent;
		max-width: 100%;
	}
	width: 100%;
`

const { appId, apiKey } = credentials
const searchClient = algoliasearch(appId, apiKey)

const OmniSearchHome = ({ defaultSearchTerm, defaultFilter }) => {
	return (
		<OmniSearchWrapper>
			<InstantSearch searchClient={searchClient} indexName="dispensaries">
				<Search
					defaultFilter={defaultFilter}
					defaultSearchTerm={defaultSearchTerm}
					focusOnLoad={false}
					resultsPosition="absolute"
				/>
			</InstantSearch>
		</OmniSearchWrapper>
	)
}
OmniSearchHome.propTypes = {
	defaultFilter: PropTypes.string,
	defaultSearchTerm: PropTypes.string,
}
export default OmniSearchHome
